<template>
  <div
    class="z-10 top-0 sticky body__header border-t border-b ticket_table_heading"
  >
    <div class="sm:items-center top-0 flex flex-wrap justify-between">

      <div class="flex flex-wrap items-baseline">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ this.title }}
        </h3>
        <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
          {{ this.subtitle }}
        </p>
      </div>
      <!--  <div class="flex flex-wrap justify-center">
        <div class="col-start-1 col-end-2 mt-1 sm:pr-10">
          <div
            :class="[
              this.$route.name === 'Tickets'
                ? 'body__header--button__active'
                : 'body__header--button',
              'rounded text-center border border-gray-300 w-40 body__header--button__text cursor-pointer',
            ]"
          >
            <button
              class="p-2 group inline-flex justify-center text-sm font-medium capitalize"
              @click="$emit('allTickets'), (activeBtn = 'allTickets')"
            >
              {{ $t("ticket.header.openTickets") }}
            </button>
          </div>
        </div>
        <div class="col-start-1 col-end-2 mt-1 sm:pr-10">
          <div
            :class="[
              activeBtn === 'closedTickets'
                ? 'body__header--button__active'
                : 'body__header--button',
              'text-center border border-gray-300 w-40 body__header--button__text cursor-pointer',
            ]"
          >
            <button
              class="p-2 group inline-flex justify-center text-sm font-medium capitalize"
              @click="$emit('ticketsClosed'), (activeBtn = 'closedTickets')"
            >
              Closed tickets
            </button>
          </div>
        </div>
        <div class="col-start-1 col-end-2 mt-1 sm:pr-10">
          <div
            :class="[
              this.$route.name === 'DayTickets'
                ? 'body__header--button__active'
                : 'body__header--button',
              'rounded text-center border border-gray-300 w-40 body__header--button__text cursor-pointer',
            ]"
          >
            <button
              class="p-2 group inline-flex justify-center text-sm font-medium capitalize"
              @click="this.$router.push(`/day-tickets`)"
            >
              {{ $t("ticket.header.ticketsforTheDay") }}
            </button>
          </div>
        </div>
        <div class="col-start-1 col-end-2 mt-1 sm:pr-10">
          <div
            :class="[
              this.$route.name === 'WeekTickets'
                ? 'body__header--button__active'
                : 'body__header--button',
              'rounded text-center border border-gray-300 w-40 body__header--button__text cursor-pointer',
            ]"
          >
            <button
              class="p-2 group inline-flex justify-center text-sm font-medium capitalize"
              @click="this.$router.push(`/week-tickets`)"
            >
              {{ $t("ticket.header.ticketsForTheWeek") }}
            </button>
          </div>
        </div>
        <div class="col-start-1 col-end-2 mt-1 sm:pr-10">
          <div
            :class="[
              this.$route.name === 'UnassignedTickets'
                ? 'body__header--button__active'
                : 'body__header--button',
              'rounded text-center border border-gray-300 w-40 body__header--button__text cursor-pointer',
            ]"
          >
            <button
              class="p-2 group inline-flex justify-center text-sm font-medium capitalize"
              @click="this.$router.push(`/unassigned-tickets`)"
            >
              {{ $t("ticket.header.ticketsUnassigned") }}
            </button>
          </div>
        </div>
        <div class="col-start-1 col-end-2 mt-1 sm:pr-10">
          <div
            :class="[
              this.$route.name === 'MyTickets'
                ? 'body__header--button__active'
                : 'body__header--button',
              'rounded text-center border border-gray-300 w-40 body__header--button__text cursor-pointer',
            ]"
          >
            <button
              class="p-2 group inline-flex justify-center text-sm font-medium capitalize"
              @click="this.$router.push(`/my-tickets`)"
            >
              {{ $t("ticket.header.myTickets") }}
            </button>
          </div>
        </div>
      </div> -->
      <div class="inline-flex">
        <!--    <Menu as="div" class="mt-1 relative inline-block text-left">
          <div class="rounded border border-gray-300">
            <MenuButton
              class="p-2 group inline-flex justify-center text-sm font-medium capitalize body__header--button__text"
            >
              {{ $t("header.filter") }}
              <FilterIcon
                class="flex-shrink-0 ml-1 h-5 w-5 text-gray-500 group-hover:body__header--button__text"
                aria-hidden="true"
              />
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="rounded origin-top-left absolute mt-2 w-60 shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem
                  v-for="status in status"
                  :key="status.id"
                  v-slot="{ active }"
                >
                  <a
                    :class="[
                      status.current
                        ? 'font-medium text-gray-900'
                        : 'text-gray-500',
                      active ? 'body__header--menu__item' : '',
                      'rounded mx-1 block px-4 py-2 text-sm cursor-pointer',
                    ]"
                    @click="this.pushSearchStatus(status.name)"
                  >
                    <div class="flex justify-between">
                      {{ status.name }}
                      <svg
                        v-show="this.searchStatus.includes(status.name)"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu> -->
        <div class="flex-1 ml-2 mt-3 xs:mt-0 lg:mt-0">
          <SearchFilter
            v-model:search="search"
            @input="$emit('update:search', this.search)"
            :inputPlaceholder="$t('header.searchTicketPlaceHolder')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { FilterIcon, SearchIcon } from "@heroicons/vue/outline";

import SearchFilter from "../../components/SearchFilter.vue";

export default {
  name: "TicketsHeader",
  props: ["searchStatus", "title", "subtitle"],
  components: {
    FilterIcon,
    SearchIcon,
    SearchFilter,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  data() {
    return {
      search: "",
      closeForm: false,
      onFocus: "Search by subject, created date or updated date",
      searchDate: "",
      searchDateUpdate: "",
      searchStatus: [],
      status: [],
      activeBtn: "",
    };
  },
  methods: {
    sendOpenForm() {
      this.$emit("openForm");
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    pushSearchStatus(status) {
      if (!this.searchStatus.includes(status)) {
        this.searchStatus.push(status);
      } else {
        let index = this.searchStatus.indexOf(status);
        if (index > -1) {
          this.searchStatus.splice(index, 1);
        }
      }
      this.$emit("pushStatusSearch", status);
    },
    async getStatus() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/statuses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.status = res.data;
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>

<style></style>
