<template>
  <TicketsHeader
    v-model:search="search"
    :searchStatus="this.searchStatus"
    @pushStatusSearch="this.pushSearchStatus($event)"
    @allTickets="this.$router.push('tickets')"
    @ticketsDay="this.getTicketsDay()"
    @ticketsWeek="this.getTicketsWeek()"
  />

  <div class="flex flex-col">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <SuccessBanner
        :key="key"
        :msg="successMsg"
        :active="activeBanner"
        @closeBanner="activeBanner = false"
        :isSuccess="isSuccess"
      />
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:">
          <TablePagination
            v-if="!backendPagination"
            rows="20"
            :tableData="filteredTickets()"
            @sort="sort($event)"
            @activeBanner="(activeBanner = true), getTickets(), (key = !key)"
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
            :statuses="status"
            @pushStatusSearch="this.pushSearchStatus($event)"
            :searchStatus="this.searchStatus"
            @clearStatusFilter="this.searchStatus.length = 0"
            :searchPriority="this.searchPriority"
            @pushPrioritySearch="this.pushSearchPriority($event)"
            @clearPriorityFilter="this.searchPriority.length = 0"
          />
          <!-- <TicketTable
            v-if="backendPagination"
            :tablePaginatedData="tickets"
            :backendPagination="backendPagination"
            :to="to"
            :from="from"
            :total="total"
            :lastPage="lastPage"
            @firstPage="currentPage = 1"
            @previousPage="
              currentPage > 1
                ? (currentPage = currentPage - 1)
                : (currentPage = 1)
            "
            @nextPage="currentPage = currentPage + 1"
            @lastPage="
              currentPage = lastPage
                ? (currentPage = lastPage)
                : (currentPage = currentPage + 1)
            "
          /> -->
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import TicketsHeader from "../../components/tickets/TicketsHeader.vue";
import TablePagination from "../../components/TablePagination.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const priorities = [
  { id: 1, name: "basse" },
  { id: 2, name: "normale" },
  { id: 3, name: "haute" },
  { id: 4, name: "critique" },
];

export default {
  name: "Tickets",
  props: ["colors"],
  components: {
    TicketsHeader,
    Loading,
    TablePagination,
    SuccessBanner,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      tickets: [],
      currentSort: "id",
      currentSortDir: "asc",
      search: "",
      searchDate: "",
      searchStatus: [],
      searchPriority: [],
      searchDateUpdate: "",
      status: [],
      to: "",
      from: "",
      total: "",
      lastPage: "",
      backendPagination: false,
      currentPage: 1,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: true,
      priorities,
    };
  },
  methods: {
    pushSearchStatus(status) {
      if (!this.searchStatus.includes(status)) {
        this.searchStatus.push(status);
      } else {
        let index = this.searchStatus.indexOf(status);
        if (index > -1) {
          this.searchStatus.splice(index, 1);
        }
      }
      this.$forceUpdate();
    },
    pushSearchPriority(priority) {
      console.log(priority);
      if (!this.searchPriority.includes(priority)) {
        this.searchPriority.push(priority);
      } else {
        let index = this.searchPriority.indexOf(priority);
        if (index > -1) {
          this.searchPriority.splice(index, 1);
        }
      }
      this.$forceUpdate();
    },
    checkPriority(id) {
      for (let index = 0; index < this.priorities.length; index++) {
        if (this.priorities[index].id == id) {
          return this.priorities[index].name;
        }
      }
    },
    async getTickets() {
      this.backendPagination = false;

      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/tickets`
        );
        if (res) {
          this.tickets = res.data.filter((ticket) => {
            return !ticket.assignee_id;
          });
          this.isLoading = false;
          this.$emit("getTicketCount");
        }
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },

    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " at " +
        dateCreated.getHours() +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },
    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name;
          }
        }
      }
    },
    sortedTickets() {
      return this.tickets.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    numberIsEqual(numb) {
      return String(numb).includes(String(this.search));
    },
    filteredTickets() {
      return this.sortedTickets().filter((ticket) => {
        if (this.searchStatus.length > 0 || this.searchPriority.length > 0) {
          let status = this.checkStatus(ticket.status_id);
          let priority = this.checkPriority(ticket.priority_id);
          if (this.search) {
            return (
              ticket.subject
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              this.formatDate(ticket.created_at)
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              this.formatDate(ticket.updated_at)
                .toLowerCase()
                .includes(this.search.toLowerCase())
            );
          }

          return (
            this.searchStatus.includes(status) ||
            this.searchPriority.includes(priority)
          );
        } else if (this.search) {
          return (
            ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
            this.formatDate(ticket.created_at)
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            this.formatDate(ticket.updated_at)
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        }
        return ticket;
      });
    },
    async getStatus() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/statuses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.status = res.data;
    },
  },
  mounted() {
    this.getStatus();
    this.getTickets();
  },
  // watch: {
  //   currentPage: function(val) {
  //     this.getTicketsClosed(val);
  //   },
  // },
};
</script>

<style></style>
